import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Base from './form_inputs/Base';

import '../stylesheets/form.scss';

const formToJson = (form) => {
  const formData = new FormData(form);
  const json = { elements: [] };
  for (const [index, answer] of formData.entries()) {
    json.elements.push({ index: Number(index), answer });
  }
  return json;
};

const submitForm = (url, body) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  fetch(url, {
    method: 'POST',
    headers,
    body
  }).then(response => {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    return response.json();
  }).catch((e) => {
    console.error(e);
  });
};

const propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  submitUrl: PropTypes.string.isRequired,
  submitted: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  disabled: false
};

const Form = ({ title, elements, submitUrl, submitted, onSubmit }) => {
  const { t } = useTranslation();

  const inputs = elements.map((element, idx) =>
    <Base element={element} id={`${element.element_type}-${element.index}`} key={idx} index={element.index} disabled={submitted}/>);

  return (
    <React.Fragment>
      <div className={'form-container'}>
        <div className={'col-md-offset-3 col-md-6'}>
          <h1><strong>{ title }</strong></h1>
          { submitted && <div className={'alert alert-success'}>{ t('submit_success') }</div>}
          <form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            const json = formToJson(e.target);
            submitForm(submitUrl, JSON.stringify(json));
            onSubmit();
          }}>
            { inputs }
            <button type={'submit'} className={'btn btn-primary pull-right'} disabled={submitted}>{ t('submit') }</button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
