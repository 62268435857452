import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  element: PropTypes.shape({
    up: PropTypes.number.isRequired,
    low: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    answer: PropTypes.string,
    is_required: PropTypes.bool
  }).isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

const Scale = ({ element, id, disabled }) => {
  return (
    <input
      type={'range'}
      className={'form-control'}
      min={element.low}
      max={element.up}
      step={element.step}
      id={id}
      name={element.index}
      defaultValue={element.answer ? element.answer[0] : undefined}
      key={element.index}
      disabled={disabled}
      required={element.is_required}
    />
  );
};

Scale.propTypes = propTypes;

export default Scale;
