import React, { useRef, useState } from 'react';
import PropTypes from "prop-types";

const RATING_COLORS = [
  '#E95659',
  '#E87558',
  '#EB8D5A',
  '#EC9F6B',
  '#F0AC44',
  '#F5C33B',
  '#E8CF34',
  '#C4CF4C',
  '#A0CF45',
  '#67CD47',
];

const propTypes = {
  element: PropTypes.shape({
    index: PropTypes.number.isRequired,
    answer: PropTypes.string,
    is_required: PropTypes.bool
  }).isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

const ScaleToTen = ({ element, id, disabled }) => {
  const ref = useRef();
  const [value, setValue] = useState(element.answer ? Number(element.answer[0]) : -1);

  return (
    <div className={'scale-to-ten'}>
      <input id={id} ref={ref} name={element.index} defaultValue={element.answer ? element.answer[0] : undefined} required/>
      {
        RATING_COLORS.map((color, idx) => {
          const selected = value === idx + 1;
          return (
            <React.Fragment key={idx}>
              <div className={`dot${selected ? ' selected' : ''}`} style={{ backgroundColor: color }} onClick={() => {
                if (disabled === false) {
                  ref.current.value = idx + 1;
                  setValue(idx + 1);
                }
              }}>
                { selected && <div>{ value }</div> }
              </div>
              { idx < 9 && <div className={'link'}/> }
            </React.Fragment>
          );
        })
      }
    </div>
  );
};

ScaleToTen.propTypes = propTypes;

export default ScaleToTen;
