import React from 'react';
import PropTypes from "prop-types";

const propTypes = {
  element: PropTypes.shape({
    index: PropTypes.number.isRequired,
    answer: PropTypes.string,
    is_required: PropTypes.bool
  }).isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};


const Text = ({ element, id, disabled }) => {
  return (
    <textarea
      className={'form-control'}
      key={element.index}
      id={id}
      name={element.index}
      defaultValue={element.answer}
      disabled={disabled}
      required={element.is_required}/>
  );
};

Text.propTypes = propTypes;

export default Text;
