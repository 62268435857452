import React from 'react';

import '../stylesheets/spinner.scss';

const Spinner = () => {
  return (
    <div className={'view-container spinner-container'} >
      <div className={'spinner'} />
    </div>);
};

export default Spinner;
