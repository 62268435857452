import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import MissionInfo from './components/MissionInfo';
import Position from './components/Position';
import Eta from './components/Eta';
import Map from './components/Map';
import Status from './components/Status';
import Spinner from './components/Spinner';
import FetchError from './components/FetchError';
import Form from './components/Form';

const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/\[/g, '\\$&').replace(/\]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const DELAY = 60000;
const Meta = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description}/>
  </Helmet>);

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      error: false,
      detailsOpen: false,
      intervalId: 0,
      fleetUrl: process.env.REACT_APP_FLEET_API_URL,
      publicId: getParameterByName('id'),
      token: getParameterByName('token'),
    };
  }

  getMission() {
    return fetch(`${window.location.protocol}//${this.state.fleetUrl}/public/1.0/missions/${this.state.publicId}/track?api_key=${this.state.token}`)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      });
  }

  componentDidMount() {
    const context = this;
    context.getMission().then(body => {
      const intervalId = setInterval(context.fetchData(context), DELAY);
      context.setState({...context.state, ...body, ready: true, intervalId});
    })
      .catch(() => context.setState({ ...context.state, error: true }));
  }

  fetchData(context) {
    return () => {
      context.getMission().then(body => context.setState({...context.state, ...body}))
        .catch(() => {
          context.setState({ ...context.state, error: true });
          if (context.state.intervalId !== 0) {
            clearInterval(context.state.intervalId);
          }
        });
    };
  }

  handleDetailsClick() {
    this.setState({
      ...this.state,
      detailsOpen: !this.state.detailsOpen
    });
  }

  render() {
    if (this.state.error) {
      return (<FetchError/>);
    }
    if (!this.state.ready) {
      return (
        <React.Fragment>
          <Meta title={this.props.t('meta.title')} description={this.props.t('meta.description')}/>
          <Spinner/>
        </React.Fragment>);
    }
    const isMissionDone = this.state.track.common_statuses.length === this.state.track.current_status.position;
    const positionComponent = !isMissionDone && this.state.track.position !== null ? <div className='col-md-3 col-sm-3 col-lg-3 hidden-xs position-container'>
      <Position position={this.state.track.position}/>
    </div> : <div className='col-md-3 hidden-xs' />;
    const etaComponent = !isMissionDone && <Eta eta={this.state.track.eta}/>;
    const phoneDetailsText = this.state.detailsOpen ? this.props.t('less_details') : this.props.t('more_details');
    const mapSlot = this.state.track.public_forms && this.state.track.public_forms.length ?
      <Form elements={this.state.track.public_forms[0].elements}
        title={this.state.track.public_forms[0].title}
        submitUrl={`${this.state.track.public_forms[0].submit_url}?api_key=${this.state.token}`}
        submitted={this.state.track.public_forms[0].submitted}
        onSubmit={() => {
          const newState = { ...this.state };
          newState.track.public_forms[0].submitted = true;
          this.setState(newState);
        }}
      /> :
      <Map
        vehicleInfo={this.state.track.user_info}
        customerLocation={this.state.track.location}/>;

    return (
      <React.Fragment>
        <Meta title={this.props.t('meta.title')} description={this.props.t('meta.description')}/>
        <div className="container-fluid">
          <div className='row'>
            { mapSlot }
          </div>
          { positionComponent }
          <div className='col-md-6 col-sm-6 col-lg-6 col-xs-12 status-eta-container'>
            <Status
              currentStatus={this.state.track.current_status}
              commonStatuses={this.state.track.common_statuses}/>
            { etaComponent }
          </div>
          <div className='col-md-3 col-sm-3 col-lg-3 hidden-xs'>
            <MissionInfo
              address={this.state.track.address}
              name={this.state.track.name}
              date={this.state.track.date}/>
          </div>
          <div className='phone'>
            <div id='details-trigger' onClick={this.handleDetailsClick.bind(this)}>
              { phoneDetailsText }
            </div>
            <div className={`details${this.state.detailsOpen ? ' visible' : ''}`}>
              <MissionInfo
                address={this.state.track.address}
                name={this.state.track.name}
                date={this.state.track.date}/>
              { positionComponent }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(App);
