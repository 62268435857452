import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, FeatureGroup, /*Popup*/ } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import '../stylesheets/map.scss';

const customerIcon = L.divIcon({ className: 'icon customer-location-icon' });
const vehicleIcon = L.divIcon({ className: 'vehicle-location-marker', html: '<div class="icon vehicle-location-icon pulse"></div>' });
const getLatLng = object => [object.lat, object.lon];

const fitBounds = (map, featureGroup) => map.fitBounds(featureGroup.current.getBounds(), { padding: [50, 50] });

const Map = (props) => {
  const {
    vehicleInfo,
    customerLocation
  } = props;
  const featureGroupRef = useRef();
  const [map, setMap] = useState(null);
  const vehicleMarker = vehicleInfo ? <Marker position={getLatLng(vehicleInfo.location)} icon={vehicleIcon}/> : '';

  useEffect(() => map !== null && fitBounds(map, featureGroupRef), [vehicleInfo]);

  return (
    <MapContainer center={[44, 0]} zoom={13} id="map" whenCreated={m => {
      fitBounds(m, featureGroupRef);
      setMap(m);
    }}>
      <TileLayer
        attribution='© OpenMapTiles'
        url="https://api.maptiler.com/maps/e734050f-87ce-4816-9f34-22f245534535/256/{z}/{x}/{y}.png?key=SkKkniaQgsAgZuRDSESq"
      />
      <FeatureGroup ref={featureGroupRef}>
        <Marker position={getLatLng(customerLocation)} icon={customerIcon}/>
        { vehicleMarker }
      </FeatureGroup>
    </MapContainer>
  );
};

Map.propTypes = {
  vehicleInfo: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
      date: PropTypes.string,
    })
  }),
  customerLocation: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  })
};

export default Map;
