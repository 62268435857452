import React from 'react';
import { useTranslation } from 'react-i18next';

import '../stylesheets/fetchError.scss';

const FetchError = () => {
  const { t } = useTranslation();
  return (
    <div className={'fetch-error'}>
      <h1>:-(</h1>
      <h1>{t('error_loading')}</h1>
    </div>
  );
};

export default FetchError;
