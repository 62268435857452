import React from 'react';
import PropTypes from "prop-types";

import Scale from './Scale';
import Text from './Text';
import ScaleToTen from './ScaleToTen';

const inputsMap = {
  scale: Scale,
  text: Text,
  scale_to_ten: ScaleToTen,
};

const propTypes = {
  element: PropTypes.shape({
    title: PropTypes.string.isRequired,
    element_type: PropTypes.string.isRequired,
    is_required: PropTypes.bool
  }).isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired
};

const defaultProps = {
  disabled: false,
  element: {
    is_required: false
  }
};

const Base = ({ element, id, disabled, index }) => {
  const Component = inputsMap[element.element_type];

  return (
    <div className={'form-group'}>
      <label htmlFor={id}><span className={'dot'}><div>{ index }</div></span> { element.title } { element.is_required && <span className={'required'}>*</span> }</label>
      <Component element={element} id={id} disabled={disabled}/>
    </div>
  );
};

Base.propTypes = propTypes;
Base.defaultProps = defaultProps;

export default Base;
