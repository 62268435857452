import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import '../stylesheets/position.scss';

const Position = (props) => {
  const {
    position
  } = props;

  const { t } = useTranslation();

  return (
    <div className="position">
      <Trans t={t} i18nKey={'position.info'} count={position}>
        {
          <b/>
        }
      </Trans>
    </div>
  );
};

Position.propTypes = {
  position: PropTypes.number
};

export default Position;
