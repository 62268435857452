import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CustomerIcon } from '../svg/user.svg';
import { ReactComponent as DateIcon } from '../svg/calendar.svg';
import { ReactComponent as AddressIcon } from '../svg/marker.svg';

import '../stylesheets/missionInfos.scss';

const MissionInfo = (props) => {
  const {
    address,
    name,
    date
  } = props;

  const { t, i18n } = useTranslation();
  const dateString = new Date(date).toLocaleString(i18n.language, { day: '2-digit', month: 'long', year: 'numeric' });

  return (
    <div className="mission-info">
      <div className="form-row">
        <CustomerIcon title={t('mission_info.icons_alt.customer')} className="mission-info-icon"/>
        <span className="mission-info-text">{name}</span>
      </div>
      <div className="form-row">
        <AddressIcon title={t('mission_info.icons_alt.address')} className="mission-info-icon"/>
        <span className="mission-info-text">{address.street}, {address.postalcode} {address.city} {address.country}</span>
      </div>
      <div className="form-row">
        <DateIcon title={t('mission_info.icons_alt.date')} className="mission-info-icon"/>
        <span className="mission-info-text">{ dateString }</span>
      </div>

    </div>
  );
};

MissionInfo.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    postalcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  date: PropTypes.string,
  name: PropTypes.string,
};

export default MissionInfo;
