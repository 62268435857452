import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CheckEmpty } from '../svg/check-empty.svg';
import { ReactComponent as CheckFull } from '../svg/check-full.svg';
import { ReactComponent as Circle } from '../svg/circle.svg';
import { ReactComponent as Warning } from '../svg/warning.svg';

import '../stylesheets/status.scss';

const StatusIcon = (props) => {
  const {
    validated,
    current,
    warning,
    title
  } = props;

  if (warning) {
    return <Warning title={title} className='status-icon status-icon-warning'/>;
  }
  else if (validated) {
    return <CheckFull title={title} className={`status-icon status-icon-validated${current ? ' status-icon-current' : ''}`}/>;
  }
  else if (current) {
    return <CheckEmpty title={title} className='status-icon status-icon-current'/>;
  }
  else {
    return <Circle title={title} className='status-icon status-icon-next'/>;
  }
};

StatusIcon.propTypes = {
  validated: PropTypes.bool,
  current: PropTypes.bool,
  warning: PropTypes.bool,
  title: PropTypes.string
};

const Status = (props) => {
  const {
    currentStatus,
    commonStatuses
  } = props;
  const currIsFinal = currentStatus.position === commonStatuses.length;
  const currIsStart = currentStatus.position === 1;
  let statuses = [];

  if (currIsStart) {
    statuses = commonStatuses.slice(0, 3);
    if (!commonStatuses.includes(currentStatus.label)) statuses.splice(0, 1, currentStatus.label);
  } else if (currIsFinal) {
    statuses = commonStatuses.slice(commonStatuses.length - 3, commonStatuses.length);
    if (!commonStatuses.includes(currentStatus.label)) statuses.splice(2, 1, currentStatus.label);
  } else {
    statuses = commonStatuses.slice(currentStatus.position - 2, currentStatus.position + 1);
    if (!commonStatuses.includes(currentStatus.label)) statuses.splice(1, 1, currentStatus.label);
  }

  const statusIcons = statuses.map((status, idx) => {
    const isCurrent = status === currentStatus.label;
    const isValidated = currIsFinal || !currIsStart && !currIsFinal && idx < 1;
    const isWarning = !commonStatuses.includes(status);
    const nextIsWarning = idx !== 2 && !commonStatuses.includes(statuses[idx + 1]);
    return (
      <React.Fragment key={idx}>
        <div className={`status${isCurrent ? ' status-current' : ''}${isValidated && !isWarning ? ' status-validated' : ''}${isWarning ? ' status-warning' : ''}`}>
          <StatusIcon current={isCurrent} validated={isValidated} warning={isWarning} title={status}/>
        </div>
        {idx !== 2 && <span className={`status-link${nextIsWarning ? ' warning' : ''}${isValidated && !nextIsWarning ? ' validated' : ''}`}/>}
      </React.Fragment>
    );
  });
  const statusTexts = statuses.map((status, idx) => {
    const isCurrent = status === currentStatus.label;
    return (
      <React.Fragment key={idx}>
        <span className={`status-text${isCurrent ? ' status-text-current': ''}`}>{status}</span>
        {idx !== 2 && <span className={'status-text-separator'}/>}
      </React.Fragment>
    );
  });

  return (
    <div className="status-container col-xs-7 col-md-12 col-lg-12 col-sm-12">
      <div className='row col-xs-4 col-md-12 col-lg-12 col-sm-12'>
        { statusIcons }
      </div>
      <div className='row col-xs-8 col-md-12 col-lg-12 col-sm-12'>
        { statusTexts }
      </div>
    </div>
  );
};

Status.propTypes = {
  currentStatus: PropTypes.shape({
    position: PropTypes.number,
    label: PropTypes.string,
  }),
  commonStatuses: PropTypes.arrayOf(PropTypes.string)
};

export default Status;
